import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import Reports from './pages/reports';
import { StrictMode } from 'react';

function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/' element={<Reports />} />
      </>
    )
  )

  return (
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  );
}

export default App;
