import axios from "axios"
import { config } from "../config/database-config"

class reports_control {

    static paidCustomer(data) {
        return new Promise((resolve, reject) => {
            axios.get(`${config.serverName}reports/PaidCustomer.php`, {
                params: {
                    year: data.year,
                    month: data.month,  
                }
            })
            .then((res) => { resolve(res) })
            .catch((err) => { reject(err) })
        })
    }

    static paidCustomerActive(data) {
        return new Promise((resolve, reject) => {
            axios.get(`${config.serverName}reports/ListCustomersActive.php`, {
                params: {
                    year: data.year,
                    month: data.month,  
                }
            })
            .then((res) => { resolve(res) })
            .catch((err) => { reject(err) })
        })
    }

    static customerList() {
        return new Promise((resolve, reject) => {
            axios.get(`${config.serverName}reports/ListCustomers.php`)
            .then((res) => { resolve(res) })
            .catch((err) => { reject(err) })
        })
    }

    static searchCustomerPayment = (id) => {
        return new Promise((resolve, reject) => {
            axios.get(`${config.serverName}reports/CustomerPayment.php?id=${id}`)
            .then((res) => { resolve(res.data) })
            .catch((err) => { reject(err) })
        })
    }

    static searchCustomerData = (id) => {
        return new Promise((resolve, reject) => {
            axios.get(`${config.serverName}costumers/GetCostumer.php?id=${id}`)
            .then((res) => { resolve(res.data) })
            .catch((err) => { reject(err) })
        })
    }

    static searchCapitalExpenditures = (searchData) => {
        return new Promise((resolve, reject) => {
            axios.get(`${config.serverName}reports/ListCapEx.php?from_date=${searchData.from_date}&to_date=${searchData.to_date}`)
            .then((res) => { resolve(res.data) })
            .catch((err) => { reject(err) })
        })
    }

    static searchOperatingExpenses = (searchData) => {
        return new Promise((resolve, reject) => {
            axios.get(`${config.serverName}reports/ListOpEx.php?from_date=${searchData.from_date}&to_date=${searchData.to_date}`)
            .then((res) => { resolve(res.data) })
            .catch((err) => { reject(err) })
        })
    }
} 

export default reports_control