import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import immsDormLogo from '../../src/assets/imms-dorm-logo.jpg';

class PDFExporter {
  static generatePDF(data, info) {
    const styles = StyleSheet.create({
      page: { padding: 20 },
      table: { display: 'flex', flexDirection: 'column', width: '100%', fontSize: '10px' },
      tableRow: { flexDirection: 'row', borderBottomColor: '#000', alignItems: 'center', border: 1, borderLeft: 1, fontSize: '10px'  },
      tableCell: { flex: 1, padding: 1 , fontSize: '10px' },
      headerCell: { backgroundColor: '#f0f0f0', fontWeight: 'bold' , fontSize: '10px' },
      image: { marginVertical: 0, marginHorizontal: 0, height: '100px', fontSize: '10px' },
      info: { marginTop: 40, marginBottom: 30, fontSize: '10px', },
      address: { 
        marginTop: 20, 
        fontSize: '12px', 
        display: 'flex', 
        justifyContent: 'flex-end', // Align text to the right
        flexDirection: 'column', // Adjust if needed
      }
    });

    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          {immsDormLogo && (
            <Image style={styles.image} src={immsDormLogo} onError={(error) => console.error('Image Error:', error)} />
          )}

          <View style={styles.info}>
            <Text>NAME: {info[0].fullname}</Text>
            <Text>DATE: {new Date().toDateString()}</Text>
          </View>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.headerCell]}>
              <View style={styles.tableCell}><Text>Amount</Text></View>
              <View style={styles.tableCell}><Text>For Payment Date</Text></View>
              <View style={styles.tableCell}><Text>Payment Date</Text></View>
            </View>
            {data.map((row) => (
              <View style={styles.tableRow} key={row.id}>
                <View style={styles.tableCell}><Text>{row.amount}</Text></View>
                <View style={styles.tableCell}><Text>{row.fpd}</Text></View>
                <View style={styles.tableCell}><Text>{row.pd}</Text></View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );
    return MyDocument;
  }

  static unpaidCustomer(dataUnpaid, searchData) {
    const styles = StyleSheet.create({
      page: { padding: 20 },
      table: { display: 'flex', flexDirection: 'column', width: '100%', fontSize: '10px' },
      tableRow: { flexDirection: 'row', borderBottomColor: '#000', alignItems: 'center', border: 1, borderLeft: 1, fontSize: '10px'  },
      tableCell: { flex: 1, padding: 1 , fontSize: '10px' },
      headerCell: { backgroundColor: '#f0f0f0', fontWeight: 'bold' , fontSize: '10px' },
      image: { marginVertical: 0, marginHorizontal: 0, height: '100px', fontSize: '10px' },
      info: { marginTop: 40, marginBottom: 15, fontSize: '10px', },
      address: { 
        marginTop: 20, 
        fontSize: '12px', 
        display: 'flex', 
        justifyContent: 'flex-end', // Align text to the right
        flexDirection: 'column', // Adjust if needed
      },
      title: { flexDirection: 'row',  marginLeft: 225, marginBottom: 15, fontSize: '10px', },
      titlesec: { flexDirection: 'row', marginBottom: 15, fontSize: '10px', },
    });

    function getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);
    
      return date.toLocaleString('en-US', { month: 'long' });
    }

    const MyDocumentUnpaid = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          {immsDormLogo && (
            <Image style={styles.image} src={immsDormLogo} onError={(error) => console.error('Image Error:', error)} />
          )}

          <View style={styles.info}>
            <Text>Date Generated: {new Date().toDateString()}</Text>
          </View>
          <View style={styles.title}>
            <Text>UNPAID CUSTOMER</Text>
          </View>
          <View style={styles.titlesec}>
            <Text>{`For the Date of ${getMonthName(searchData.month)} ${searchData.year}`}</Text>
          </View>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.headerCell]}>
              <View style={styles.tableCell}><Text>No</Text></View>
              <View style={styles.tableCell}><Text>ID Number</Text></View>
              <View style={styles.tableCell}><Text>Name</Text></View>
            </View>
            {dataUnpaid.map((row, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCell}><Text>{row.no}</Text></View>
                <View style={styles.tableCell}><Text>{row.id_number}</Text></View>
                <View style={styles.tableCell}><Text>{row.fullname}</Text></View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );

    return MyDocumentUnpaid;
  }

  static paidCustomer(data, searchData) {
    const styles = StyleSheet.create({
      page: { padding: 20 },
      table: { display: 'flex', flexDirection: 'column', width: '100%', fontSize: '10px' },
      tableRow: { flexDirection: 'row', borderBottomColor: '#000', alignItems: 'center', border: 1, borderLeft: 1, fontSize: '10px'  },
      tableCell: { flex: 1, padding: 1 , fontSize: '10px' },
      headerCell: { backgroundColor: '#f0f0f0', fontWeight: 'bold' , fontSize: '10px' },
      image: { marginVertical: 0, marginHorizontal: 0, height: '100px', fontSize: '10px' },
      info: { marginTop: 40, marginBottom: 30, fontSize: '10px', },
      address: { 
        marginTop: 20, 
        fontSize: '12px', 
        display: 'flex', 
        justifyContent: 'flex-end', // Align text to the right
        flexDirection: 'column', // Adjust if needed
      },
      title: { flexDirection: 'row',  marginLeft: 225, marginBottom: 15, fontSize: '10px', },
      titlesec: { flexDirection: 'row', marginBottom: 15, fontSize: '10px', },
    });

    function getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);
    
      return date.toLocaleString('en-US', { month: 'long' });
    }

    const MyDocumentPaid = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          {immsDormLogo && (
            <Image style={styles.image} src={immsDormLogo} onError={(error) => console.error('Image Error:', error)} />
          )}

          <View style={styles.info}>
            <Text>Date Generated: {new Date().toDateString()}</Text>
          </View>
          <View style={styles.title}>
            <Text>PAID CUSTOMER</Text>
          </View>
          <View style={styles.titlesec}>
            <Text>{`For the Date of ${getMonthName(searchData.month)} ${searchData.year}`}</Text>
          </View>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.headerCell]}>
              <View style={styles.tableCell}><Text>No</Text></View>
              <View style={styles.tableCell}><Text>ID Number</Text></View>
              <View style={styles.tableCell}><Text>Name</Text></View>
            </View>
            {data.map((row, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCell}><Text>{row.no}</Text></View>
                <View style={styles.tableCell}><Text>{row.id_number}</Text></View>
                <View style={styles.tableCell}><Text>{row.fullname}</Text></View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );
    return MyDocumentPaid;
  }

  static listCaPexOpEx(data, searchData, selectedCategory) {
    const styles = StyleSheet.create({
      page: { padding: 20 },
      table: { display: 'flex', flexDirection: 'column', width: '100%', fontSize: '10px' },
      tableRow: { flexDirection: 'row', borderBottomColor: '#000', alignItems: 'center', border: 1, borderLeft: 1, fontSize: '10px'  },
      tableCell: { flex: 1, padding: 1 , fontSize: '10px' },
      headerCell: { backgroundColor: '#f0f0f0', fontWeight: 'bold' , fontSize: '10px' },
      image: { marginVertical: 0, marginHorizontal: 0, height: '100px', fontSize: '10px' },
      info: { marginTop: 40, marginBottom: 15, fontSize: '10px', },
      address: { 
        marginTop: 20, 
        fontSize: '12px', 
        display: 'flex', 
        justifyContent: 'flex-end', // Align text to the right
        flexDirection: 'column', // Adjust if needed
      },
      title: { flexDirection: 'row',  marginLeft: 225, marginBottom: 15, fontSize: '10px', },
      titlesec: { flexDirection: 'row', marginBottom: 15, fontSize: '10px', },
    });

    const MyDocumentUnpaid = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          {immsDormLogo && (
            <Image style={styles.image} src={immsDormLogo} onError={(error) => console.error('Image Error:', error)} />
          )}

          <View style={styles.info}>
            <Text>Date Generated: {new Date().toDateString()}</Text>
          </View>
          <View style={styles.title}>
            <Text>{Number(selectedCategory) === 4 ? "Operational Expenditure List" : "Capital Expenditure List"}</Text>
          </View>
          <View style={styles.titlesec}>
            <Text>{`For the Date of ${searchData.from_date} to ${searchData.to_date}`}</Text>
          </View>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.headerCell]}>
              <View style={styles.tableCell}><Text>Date</Text></View>
              <View style={styles.tableCell}><Text>Category</Text></View>
              <View style={styles.tableCell}><Text>Item</Text></View>
              <View style={styles.tableCell}><Text>Amount</Text></View>
            </View>
            {data.map((row, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCell}><Text>{row.Date}</Text></View>
                <View style={styles.tableCell}><Text>{row.Category}</Text></View>
                <View style={styles.tableCell}><Text>{row.Item_name}</Text></View>
                <View style={styles.tableCell}><Text>{row.Price}</Text></View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );

    return MyDocumentUnpaid;
  }

}

export default PDFExporter;