import React, { useState } from "react";
import DataTable from 'react-data-table-component';
import reports_control from './../controller/report_control' 
import helper from "../helper/helper";
import Excel from "../component/excel";
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFExporter from "../component/pdf";

function Reports () {
        
    
    const columns = [{ name: 'No', selector: row => row.no }, { name: 'ID Number', selector: row => row.id_number } , { name: 'Name', selector: row => row.fullname } ]
    const columnsOpExCapEx = [ { name: 'Date', selector: row => row.Date }, {  name: 'Item Name', selector: row => row.Item_name }, { name: 'Category', selector: row => row.Category }, { name: 'Price', selector: row => row.Price } ]
    const customerColumns = [ { name: 'Amount', selector: row => row.amount }, { name: 'For Payment Date', selector: row => row.fpd }, { name: 'Payment Date', selector: row => row.pd } ]

    const [ selectedCategory, setSelectedCategory ] = useState(0)
    const [ searchData, setSearchData ] = useState([])
    const [ data, setData ] = useState([])
    const [ totalPayment, setTotalPayment ] = useState(0)
    const [ customerData, setCustomerData ] = useState([])
    const [ tenanIdNumber, setTenanIdNumber ] = useState('')
    const [ tenanName, setTenantName ] = useState('')
    const [ customerInfo, setCustomerInfo ] = useState([])
    const [ dataUnpaid, setDataUnpaid ] = useState([])
    const [ dataPaid, setDataPaid ] = useState([])
    const [ dataCapExOpEx, setDataCapExOpEx ] = useState([])

    const MyDocument = PDFExporter.generatePDF(customerData, customerInfo);
    const UnpaidDataDoc = PDFExporter.unpaidCustomer(dataUnpaid, searchData);
    const PaidDataDoc = PDFExporter.paidCustomer(dataPaid, searchData);
    const OpExDoc = PDFExporter.listCaPexOpEx(dataCapExOpEx, searchData, selectedCategory)

    const inputData = (e) => {
        setSearchData({
            ...searchData,
            [e.target.name]: e.target.value
        }) 
    }

    const handleSelectedCategory = (e) => {
        setDataCapExOpEx([])
        setSelectedCategory(Number(e.target.value))
    }

    const handleExport = () => {
        switch(Number(selectedCategory)) {
            case 1: 
                Excel.ExportDataToExcel(data, "Paid Tenant's")
                break;
            case 2: 
                Excel.ExportDataToExcel(data, "Unpaid Tenant's")
                break;
            default:
        }
    }

    
    /*
    const handleDownload = () => {
    Excel.TenantExportDataToExcel(customerData, tenanName)
    }
    */

    const handleSubmitSearch = async (e) => {
        e.preventDefault();
        try {

            // This February 8 2024, i will start fixing myself to be a good programmer and partner!
            if (Number(selectedCategory) === 1) {
                const res = await reports_control.paidCustomer(searchData);
                const objectData = res.data.map((item, index) => ({
                    no: index+1,
                    id_number: item.id_number,
                    fullname: item.fullname,
                }))
                var totalAmount = 0;
                res.data.map((item) => 
                    totalAmount = totalAmount + item.amount
                )
                setTotalPayment(totalAmount)
                setDataPaid(objectData)
                setData(objectData);
            } 
            
            else if (Number(selectedCategory) === 2) {
                var paidCustomer = await reports_control.paidCustomer(searchData);
                var customerList = await reports_control.paidCustomerActive(searchData)
                
                paidCustomer = paidCustomer.data.map((item) => ({
                    id_number: item.id_number,
                    fullname: item.fullname
                }))
                
                customerList = customerList.data.map((item) => ({
                    id_number: item.id_number,
                    fullname: item.fullname,
                })) 

                const data = helper.filterRemoveData(customerList, paidCustomer)
                setData(data.map((item, index) => ({
                    no: index+1,
                    id_number: item.id_number,
                    fullname: item.fullname,
                })))
                
                setDataUnpaid(data.map((item, index) => ({
                    no: index+1,
                    id_number: item.id_number,
                    fullname: item.fullname,
                })))
            } else {
                console.log('Invalid Category!')
            }
        } catch (err) {
            console.error(err);
        } finally {

        }
    };

    const handleSubmitSearchOpExCapEx = async (e) => {
        e.preventDefault();
        try {
            if (Number(selectedCategory) === 4) {
                const res = await reports_control.searchCapitalExpenditures(searchData)
                setDataCapExOpEx(res.map((item) => ({
                    Date: item.Date,
                    Category: item.Category,
                    Item_name: item.Item_name,
                    Price: item.Price
                })))
            } 
            else if (Number(selectedCategory) === 5) {
                const res = await reports_control.searchOperatingExpenses(searchData)
                setDataCapExOpEx(res.map((item) => ({
                    Date: item.Date,
                    Category: item.Category,
                    Item_name: item.Item_name,
                    Price: item.Price
                })))
            } else {
                console.log("Invalid Category!")
            }
        } catch (err) {
            console.log(err)
        }

    }

    const handleSubmitSearchCustomer = (e) => {
        e.preventDefault();

        // Reset
        setCustomerData([])
        reports_control.searchCustomerData(tenanIdNumber)
        .then((res) => {
            setCustomerInfo(res)
            if (res.length > 0) {
                setTenantName(res[0].fullname)
            } else {
                alert('Customer Not Found!')
            }
        })
        .catch((err) => {
            console.log(err)
        })

        reports_control.searchCustomerPayment(tenanIdNumber)
        .then((res) => {
            setCustomerData(res.map((item) => ({
                amount: item.amount,
                fpd: item.for_payment_date,
                pd: item.date_payment,
            })))
        })
        .catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            <>
                <div className="card shadow border-0 m-3">
                    <div className="container-fluid p-3 row">
                        <div className="col-3">
                            <label htmlFor="report_category">Report Category</label>
                            <div className="mt-6">
                                <select id="report_category" className="form-control" onChange={handleSelectedCategory}>
                                    <option value='0'></option>
                                    <option value='1'>Paid Customer</option>
                                    <option value='2'>Unpaid Customer</option>
                                    <option value='3'>Customer Payments</option>
                                    <option value='4'>Operational Expenditure List</option>
                                    <option value='5'>Capital Expenditure List</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            {
                Number(selectedCategory) === 1 || Number(selectedCategory) === 2  ?
                <>
                    <div className="card shadow border-0 m-3">
                        <div className="container-fluid p-3 row">
                            <div className="col-12 mt-2">
                                <form onSubmit={handleSubmitSearch} noValidate>
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-2">
                                            <label htmlFor="month_name">Month</label>
                                            <select className="form-control" onChange={inputData} name="month" required>
                                                <option value=''></option>
                                                <option value='1'>January</option>
                                                <option value='2'>February</option>
                                                <option value='3'>March</option>
                                                <option value='4'>April</option>
                                                <option value='5'>May</option>
                                                <option value='6'>June</option>
                                                <option value='7'>July</option>
                                                <option value='8'>August</option>
                                                <option value='9'>September</option>
                                                <option value='10'>October</option>
                                                <option value='11'>November</option>
                                                <option value='12'>December</option>
                                            </select>
                                        </div>
                                        <div className="col-2">
                                            <label htmlFor="month_name">Year</label>
                                            <input type="number" onChange={inputData} min={2000} max={4000} name="year" className="form-control" required/>
                                        </div>
                                        <div className="col-2 mt-4">
                                            <input type="submit" className="btn btn-primary form-control" value='Search' />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow border-0 m-3">
                        <div className="row">
                        {
                            Number(selectedCategory) === 1 && dataPaid.length > 0 ? 
                            <>
                                <PDFDownloadLink className="btn btn-primary m-3" document={<PaidDataDoc />} fileName={`Paid_${new Date().toLocaleDateString()}.pdf`}>
                                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Paid Download now!')}
                                </PDFDownloadLink>
                            </>
                            :
                            Number(selectedCategory) === 2 && dataUnpaid.length > 0 ?
                            <>
                                <PDFDownloadLink className="btn btn-primary m-3" document={<UnpaidDataDoc />} fileName={`Unpaid_${new Date().toLocaleDateString()}.pdf`}>
                                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Unpaid Download now!')}
                                </PDFDownloadLink>
                            </>
                            : 
                            null
                        }
                        </div>
                        <DataTable
                            columns={ columns } 
                            data={data}
                            pagination={true}
                            paginationPerPage={10}
                        />
                    </div>
                </>
                :
                Number(selectedCategory) === 3 ?
                <>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card border-0 shadow">
                                    <form onSubmit={handleSubmitSearchCustomer}>
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-3 p-4">
                                                <label><b>Tenant's ID Number</b></label>
                                                <input type="text" name="search_name" className="form-control" onChange={(e) => {setTenanIdNumber(e.target.value)}} />
                                            </div>
                                            <div className="col-2 p-4">
                                                <input type="submit" className="form-control mt-4 btn btn-primary" />
                                            </div>
                                            <div className="col-12">
                                                { /*<button onClick={handleDownload} className="btn btn-primary m-3">DOWNLOAD</button> */}
                                                {
                                                    customerData.length > 0 ? 
                                                        <>
                                                            <PDFDownloadLink className="btn btn-primary m-3" document={<MyDocument />} fileName={`${tenanName}.pdf`}>
                                                                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                                                            </PDFDownloadLink>
                                                        </>
                                                    :
                                                        null
                                                }
                                                <DataTable
                                                    columns={ columnsOpExCapEx } 
                                                    data={customerData}
                                                    pagination={true}
                                                    paginationPerPage={10}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                Number(selectedCategory) === 4 || Number(selectedCategory) === 5 ?
                <>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card border-0 shadow">
                                    <form onSubmit={handleSubmitSearchOpExCapEx}>
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-3 p-4">
                                                <label><b>From Date</b></label>
                                                <input type="date" name="from_date" className="form-control" onChange={inputData} />
                                            </div>
                                            <div className="col-3 p-4">
                                                <label><b>To Date</b></label>
                                                <input type="date" name="to_date" className="form-control" onChange={inputData} />
                                            </div>
                                            <div className="col-2 p-4">
                                                <input type="submit" className="form-control mt-4 btn btn-primary" />
                                            </div>
                                            <div className="col-12">
                                                { /*<button onClick={handleDownload} className="btn btn-primary m-3">DOWNLOAD</button> */}
                                                {
                                                    dataCapExOpEx.length > 0 ? 
                                                        <>
                                                            <PDFDownloadLink className="btn btn-primary m-3" document={<OpExDoc />} fileName={`${Number(selectedCategory) === 4 ? "Operational Expenditure List" : "Capital Expenditure List"}.pdf`}>
                                                                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                                                            </PDFDownloadLink>
                                                        </>
                                                    :
                                                        null
                                                }
                                                <DataTable
                                                    columns={ columnsOpExCapEx } 
                                                    data={ dataCapExOpEx }
                                                    pagination={true}
                                                    paginationPerPage={10}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                null
            }
        </>
    )
}

export default Reports;