import React from 'react';
import dorm_logo from '../assets/imms-dormitory.png';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

class Excel extends React.Component {
  static ExportDataToExcel = async (datas, title) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // Create worksheet
    const wsData = XLSX.utils.json_to_sheet(datas);

    // Add custom headers
    XLSX.utils.sheet_add_aoa(wsData, [['NO', 'ID NO.', 'FULLNAME']], { origin: 'A1' });

    // Add auto-fit to all columns if !cols exists
    if (wsData['!cols']) {
      for (let i = 5; i < wsData['!cols'].length + 5; i++) {
        wsData['!cols'][i] = { width: XLSX.utils.find_col_width(wsData, i) };
      }
    }

    // Add borders to all cells
    const range = XLSX.utils.decode_range(wsData['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { r: R, c: C };
        const cellRef = XLSX.utils.encode_cell(cellAddress);

        // Add borders to each cell
        wsData[cellRef].s = {
          border: { bottom: { style: 'thin', color: { auto: 3 } } },
        };
      }
    }

    // Create a new Image instance
    const img = new Image();
    img.crossOrigin = 'Anonymous';

    // Wait for the image to load
    await new Promise((resolve) => {
      img.onload = resolve;
      img.src = dorm_logo;
    });

    // Calculate the position for the image header in cell A1 with border
    const imgHeader = {
      t: 'image',
      v: dorm_logo, // Use dorm_logo directly as the value
      position: {
        type: 'oneCellAnchor',
        from: { col: 0, colOff: '0px', row: 0, rowOff: '0px' },
        to: { col: 1, colOff: '0px', row: 2, rowOff: '0px' },
        border: { color: '000000', style: 1, width: 1 }, // Border settings
      },
    };

    wsData['!drawing'] = wsData['!drawing'] || [];
    wsData['!drawing'].push(imgHeader);

    // Calculate the position for the image footer in cell A100 with border
    const imgFooter = {
      t: 'image',
      v: dorm_logo, // Use dorm_logo directly as the value
      position: {
        type: 'oneCellAnchor',
        from: { col: 0, colOff: '0px', row: 100, rowOff: '0px' },
        to: { col: 1, colOff: '0px', row: 102, rowOff: '0px' },
        border: { color: '000000', style: 1, width: 1 }, // Border settings
      },
    };

    wsData['!drawing'].push(imgFooter);

    const wb = { Sheets: { data: wsData }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, title + ' ' + new Date().toDateString() + ' ' + fileExtension);
  };

  static TenantExportDataToExcel = async (datas, title) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // Create worksheet
    const wsData = XLSX.utils.json_to_sheet(datas);

    // Add custom headers
    XLSX.utils.sheet_add_aoa(wsData, [['Amount', 'For Payment Date', 'Payment Date']], { origin: 'A1' });

    // Add auto-fit to all columns if !cols exists
    if (wsData['!cols']) {
      for (let i = 5; i < wsData['!cols'].length + 5; i++) {
        wsData['!cols'][i] = { width: XLSX.utils.find_col_width(wsData, i) };
      }
    }

    // Add borders to all cells
    const range = XLSX.utils.decode_range(wsData['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { r: R, c: C };
        const cellRef = XLSX.utils.encode_cell(cellAddress);

        // Add borders to each cell
        wsData[cellRef].s = {
          border: { bottom: { style: 'thin', color: { auto: 3 } } },
        };
      }
    }

    // Create a new Image instance
    const img = new Image();
    img.crossOrigin = 'Anonymous';

    // Wait for the image to load
    await new Promise((resolve) => {
      img.onload = resolve;
      img.src = dorm_logo;
    });

    // Calculate the position for the image header in cell A1 with border
    const imgHeader = {
      t: 'image',
      v: dorm_logo, // Use dorm_logo directly as the value
      position: {
        type: 'oneCellAnchor',
        from: { col: 0, colOff: '0px', row: 0, rowOff: '0px' },
        to: { col: 1, colOff: '0px', row: 2, rowOff: '0px' },
        border: { color: '000000', style: 1, width: 1 }, // Border settings
      },
    };

    wsData['!drawing'] = wsData['!drawing'] || [];
    wsData['!drawing'].push(imgHeader);

    // Calculate the position for the image footer in cell A100 with border
    const imgFooter = {
      t: 'image',
      v: dorm_logo, // Use dorm_logo directly as the value
      position: {
        type: 'oneCellAnchor',
        from: { col: 0, colOff: '0px', row: 100, rowOff: '0px' },
        to: { col: 1, colOff: '0px', row: 102, rowOff: '0px' },
        border: { color: '000000', style: 1, width: 1 }, // Border settings
      },
    };

    wsData['!drawing'].push(imgFooter);

    const wb = { Sheets: { data: wsData }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, title + ' ' + new Date().toDateString() + ' ' + fileExtension);
  };

  static getImageDataUri = (img) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      }, 'image/png');
    });
  };
}

export default Excel;
