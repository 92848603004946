class helper {

    static filterRemoveData(listData, removeData) {

        let filteredData = listData.filter((item1) => !removeData.some((item2) => String(item1.id_number) === String(item2.id_number)));
        let unique = [];

        filteredData.forEach((item) => {
            if (!unique.includes(item)) {
                unique.push(item)
            }
        });
        return unique;
    }
}

export default helper;
